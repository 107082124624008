import uniImg1 from '../../images/uniImg1.jpg'
import uniImg2 from '../../images/uniImg2.png'
import uniImg3 from '../../images/uniImg3.jpg'
import uniImg4 from '../../images/uniImg4.jpg'
import uniImg5 from '../../images/uniImg5.jpg'
import uniImg6 from '../../images/uniImg6.jpg'
import uniImg7 from '../../images/uniImg7.jpg'
import uniImg8 from '../../images/uniImg8.jpg'
import uniImg9 from '../../images/uniImg9.jpg'
import uniImg10 from '../../images/uniImg10.jpg'
import uniImg11 from '../../images/uniImg11.jpg'
import uniImg12 from '../../images/uniImg12.jpg'

function UniversityCards() {
  return (

    <div className="bg-slate-800">
    <section className="md:h-full flex items-center text-white py-4">
      <div className="container px-5  mx-auto">
        <div className="flex flex-wrap mb-8">
          <div className="md:mb-8 -mb-12">
        
        </div>
        <hr className="h-px my-8 bg-white border-0 dark:bg-white" />
  {/* First-CARD */}
          <div className="p-4 sm:w-1/2 lg:w-1/3 ">
            <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden hover:bg-gray-100 hover:text-black">
              <img
                className="lg:h-72 md:h-48 w-full object-cover object-center"
                src={uniImg1}
                alt="Img"
              />
              <div className="p-6  transition duration-300 ease-in">
                <a href="https://mdu.ac.in/" className="cursor-pointer">
                  <h1 className=" cursor-pointer text-2xl font-semibold mb-3">
                  Maharshi Dayanand University
                  </h1>
                  <p className="leading-relaxed mb-3 cursor-pointer">
                  University in Rohtak, Haryana.
                  <br/>
                  All the undergraduate and postgraduate programmes offered by Maharishi Dayanand University Rohtak are recognised by the University Grants Commission (UGC).
                  </p>
                  <div className="flex items-center flex-wrap ">
                    <p className="text-indigo-300 inline-flex items-center md:mb-2 lg:mb-0">
                      Click Here For More Information
                      <svg
                        className="w-4 h-4 ml-2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M5 12h14"></path>
                        <path d="M12 5l7 7-7 7"></path>
                      </svg>
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
 {/* Second--CARD  */}
          <div className="p-4 sm:w-1/2 lg:w-1/3">
            <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden hover:bg-gray-100 hover:text-black">
              <img
                className="lg:h-72 md:h-48 w-full object-cover object-center"
                src={uniImg2}
                alt="Img"
              />
              <div className="p-6  transition duration-300 ease-in">
                <a href="https://www.kuk.ac.in/" className="cursor-pointer">
                  <h1 className=" cursor-pointer text-2xl font-semibold mb-3">
                    Kurukhshetra University
                  </h1>
                  <p className="leading-relaxed mb-3 cursor-pointer">
                  University in Kurukshetra, Haryana.
                  <br/>
                  Kurukshetra University, Kurukshetra (KUK) is <br/> a State University established in the year of 1956 and it is also one of the oldest universities in the nation and a unitary residential University located in the holy city of Kurukshetra, Haryana.
                  </p>
                  <div className="flex items-center flex-wrap ">
                    <p className="text-indigo-300 pb-1 inline-flex items-center md:mb-2 lg:mb-0">
                      Click Here For More Information
                      <svg
                        className="w-4 h-4 ml-2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M5 12h14"></path>
                        <path d="M12 5l7 7-7 7"></path>
                      </svg>
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
 {/* Third--CARD  */}
          <div className="p-4 sm:w-1/2 lg:w-1/3">
            <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden hover:bg-gray-100 hover:text-black">
              <img
                className="lg:h-72 md:h-48 w-full object-cover object-center"
                src={uniImg3}
                alt="Img"
              />
              <div className="p-6  transition duration-300 ease-in">
                <a href="https://www.rtu.ac.in/index/" className="cursor-pointer">
                  <h1 className=" cursor-pointer text-2xl font-semibold mb-3">
                   Rajasthan Technical University
                  </h1>
                  <p className="leading-relaxed mb-3 cursor-pointer">
                  University in Kota, Rajasthan.
                  <br/>
                  Rajasthan Technical University (RTU) is located in Kota in the state of Rajasthan. It was established in 2006 by the Government of Rajasthan to enhance the technical education in the state.
                  </p>
                  <div className="flex items-center flex-wrap ">
                    <p className="text-indigo-300 inline-flex items-center md:mb-2 lg:mb-0">
                      Click Here For More Information
                      <svg
                        className="w-4 h-4 ml-2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M5 12h14"></path>
                        <path d="M12 5l7 7-7 7"></path>
                      </svg>
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
 {/* Fourth--CARD  */}
          <div className="p-4 sm:w-1/2 lg:w-1/3">
            <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden hover:bg-gray-100 hover:text-black">
              <img
                className="lg:h-72 md:h-48 w-full object-cover object-center"
                src={uniImg4}
                alt="Img"
              />
              <div className="p-6  transition duration-300 ease-in">
                <a href="https://www.bput.ac.in/" className="cursor-pointer">
                  <h1 className=" cursor-pointer text-2xl font-semibold mb-3">
                    Biju Patnaik University of Technology
                  </h1>
                  <p className="leading-relaxed mb-3 cursor-pointer">
                  
                  Biju Patnaik University of Technology (BPUT) is a public state university located in Rourkela, Odisha, India. It was established in 2002 and was named after Biju Patnaik, the former Chief Minister of Odisha. It is ranked at the 190th position by NIRF 2021 rankings in the Engineering category list.
                  </p>
                  <div className="flex items-center flex-wrap ">
                    <p className="text-indigo-300  inline-flex items-center md:mb-2 py-2 pb-4 lg:mb-0">
                      Click Here For More Information
                      <svg
                        className="w-4 h-4 ml-2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M5 12h14"></path>
                        <path d="M12 5l7 7-7 7"></path>
                      </svg>
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
 {/* Fifth--CARD  */}
          <div className="p-4 sm:w-1/2 lg:w-1/3">
            <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden hover:bg-gray-100 hover:text-black">
              <img
                className="lg:h-72 md:h-48 w-full object-cover object-center"
                src={uniImg5}
                alt="Img"
              />
              <div className="p-6  transition duration-300 ease-in">
                <a href="https://aktu.ac.in" className="cursor-pointer">
                  <h1 className=" cursor-pointer text-2xl font-semibold mb-3">
                    A.K.T.U
                  </h1>
                  <p className="leading-relaxed mb-3 cursor-pointer">
                    University in Lucknow, U.P<br/>
                  Dr A.P.J. Abdul Kalam Technical University (AKTU), Lucknow was established by the Government of Uttar Pradesh in 2000. In Technical Education, AKTU offers programmes of education, research and training in Engineering, Technology, Architecture, Town Planning, Pharmacy, Applied Arts & Crafts, etc.

                  </p>
                  <div className="flex items-center flex-wrap ">
                    <p className="text-indigo-300 pb-1 inline-flex items-center md:mb-2 lg:mb-0">
                      Click Here For More Information
                      <svg
                        className="w-4 h-4 ml-2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M5 12h14"></path>
                        <path d="M12 5l7 7-7 7"></path>
                      </svg>
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
 {/* Sixth--CARD  */}
          <div className="p-4 sm:w-1/2 lg:w-1/3">
            <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden hover:bg-gray-100 hover:text-black">
              <img
                className="lg:h-72 md:h-48 w-full object-cover object-center"
                src={uniImg6}
                alt="Img"
              />
              <div className="p-6  transition duration-300 ease-in">
                <a href="https://uktech.ac.in/en" className="cursor-pointer">
                  <h1 className=" cursor-pointer text-2xl font-semibold mb-3">
                    Uttarakhand Technical University
                  </h1>
                  <p className="leading-relaxed mb-3 cursor-pointer">
                    University in Dehradun, Uttarakhand <br/>
                    Uttarakhand Technical University, Dehradun was established on 27th January 2005 by Govt. of Uttarakhand through the Uttarakhand Technical University Act 2005. The Veer Madho Singh Bhandari Uttarakhand Technical University campus is situated at NH-72 Suddhowala, Dehradun.
                  </p>
                  <div className="flex items-center flex-wrap ">
                    <p className="text-indigo-300 inline-flex items-center md:mb-2 lg:mb-0">
                      Click Here For More Information
                      <svg
                        className="w-4 h-4 ml-2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M5 12h14"></path>
                        <path d="M12 5l7 7-7 7"></path>
                      </svg>
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>

          {/* Seventh Card */}
          <div className="p-4 sm:w-1/2 lg:w-1/3">
            <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden hover:bg-gray-100 hover:text-black">
              <img
                className="lg:h-72 md:h-48 w-full object-cover object-center"
                src={uniImg7}
                alt="Img"
              />
              <div className="p-6  transition duration-300 ease-in">
                <a href="https://vtu.ac.in/en/" className="cursor-pointer">
                  <h1 className=" cursor-pointer text-2xl font-semibold mb-3">
                  Visvesvaraya Technological University
                  </h1>
                  <p className="leading-relaxed mb-3 cursor-pointer">
                   University in Karnataka <br/>
                   VTU is a collegiate public university in Karnataka. A constituent college, 17 autonomous colleges, 13 quality improvement programs & a total number of 219 affiliated colleges are under its affiliation. Established in 1960, the institute is among 31 National Institutes of Technology (NITs) in the country.
                  </p>
                  <div className="flex items-center flex-wrap ">
                    <p className="text-indigo-300 inline-flex items-center md:mb-2 lg:mb-0">
                      Click Here For More Information
                      <svg
                        className="w-4 h-4 ml-2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M5 12h14"></path>
                        <path d="M12 5l7 7-7 7"></path>
                      </svg>
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>

          {/* Eighth Card  */}
          <div className="p-4 sm:w-1/2 lg:w-1/3">
            <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden hover:bg-gray-100 hover:text-black">
              <img
                className="lg:h-72 md:h-48 w-full object-cover object-center"
                src={uniImg8}
                alt="Img"
              />
              <div className="p-6 transition duration-300 ease-in">
                <a href="https://mrsptu.ac.in/" className="cursor-pointer">
                  <h1 className=" cursor-pointer text-2xl font-semibold mb-3">
                  Maharaja Ranjit Singh Punjab Technical University
                  </h1>
                  <p className="leading-relaxed mb-3 cursor-pointer">
                    University in Punjab <br/>
                  Maharaja Ranjit Singh Punjab Technical University, abbreviated as MRSPTU, was established by the Punjab State Government in the year 2015 at Bathinda. The University offers UG, PG, Ph. D. programmes. A State University Established By Govt. of Punjab vide Punjab Act No. 5 of 2015 and Approved Under Section 2(f) & 12 (B) of UGC
                  </p>
                  <div className="flex items-center flex-wrap ">
                    <p className="text-indigo-300 inline-flex items-center md:mb-2 lg:mb-0">
                      Click Here For More Information
                      <svg
                        className="w-4 h-4 ml-2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M5 12h14"></path>
                        <path d="M12 5l7 7-7 7"></path>
                      </svg>
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>

          {/* Nineth Card  */}
          <div className="p-4 sm:w-1/2 lg:w-1/3">
            <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden hover:bg-gray-100 hover:text-black">
              <img
                className="lg:h-72 md:h-48 w-full object-cover object-center"
                src={uniImg9}
                alt="Img"
              />
              <div className="p-6 transition duration-300 ease-in">
                <a href="https://ptu.ac.in/" className="cursor-pointer">
                  <h1 className=" cursor-pointer text-2xl font-semibold mb-3">
                  Gujral Punjab Technical University
                  </h1>
                  <p className="leading-relaxed mb-3 cursor-pointer">
                  Gujral Punjab Technical University (IKGPTU) was established by an Act of State Legislature on 16th January, 1997, to promote technical, management and pharmaceutical education in the state at degree level and above. It is approved by UGC, AICTE. The overall NIRF ranking is 3rd in State, 33rd in research, and 54th among the top 100 Universities of India.
                  </p>
                  <div className="flex items-center flex-wrap ">
                    <p className="text-indigo-300 inline-flex items-center md:mb-2 lg:mb-0">
                      Click Here For More Information
                      <svg
                        className="w-4 h-4 ml-2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M5 12h14"></path>
                        <path d="M12 5l7 7-7 7"></path>
                      </svg>
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>

          {/* Tenth Card  */}

          <div className="p-4 sm:w-1/2 lg:w-1/3">
            <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden hover:bg-gray-100 hover:text-black">
              <img
                className="lg:h-72 md:h-48 w-full object-cover object-center"
                src={uniImg10}
                alt="Img"
              />
              <div className="p-6 transition duration-300 ease-in">
                <a href="https://www.ccsuniversity.ac.in/ccsum/" className="cursor-pointer">
                  <h1 className=" cursor-pointer text-2xl font-semibold mb-3">
                  Chaudhary Charan Singh University
                  </h1>
                  <p className="leading-relaxed mb-3 cursor-pointer">
                  Chaudhary Charan Singh University (CCS University), formerly Meerut University, is a public state university located in Meerut, Uttar Pradesh, India. Presently, it is one of the premier educational institutions of the country encompassing a vast, beautiful and pollution-free campus which sprawls over 222 acres of land.
                  </p>
                  <div className="flex items-center flex-wrap ">
                    <p className="text-indigo-300 inline-flex items-center md:mb-2 lg:mb-0">
                      Click Here For More Information
                      <svg
                        className="w-4 h-4 ml-2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M5 12h14"></path>
                        <path d="M12 5l7 7-7 7"></path>
                      </svg>
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>

          {/* Eleventh Card  */}

          <div className="p-4 sm:w-1/2 lg:w-1/3">
            <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden hover:bg-gray-100 hover:text-black">
              <img
                className="lg:h-72 md:h-48 w-full object-cover object-center"
                src={uniImg11}
                alt="Img"
              />
              <div className="p-6  transition duration-300 ease-in">
                <a href="https://www.rgpv.ac.in/" className="cursor-pointer">
                  <h1 className=" cursor-pointer text-2xl font-semibold mb-3">
                  Rajiv Gandhi Proudyogiki Vishwavidyalaya
                  </h1>
                  <p className="leading-relaxed mb-3 cursor-pointer">
                  University in Bhopal, Madhya Pradesh<br/><br/>
                  UIT-RGPV is one of the premier engineering institutions in Central India. The Institution was established in the year 1986, by the Government of Madhya Pradesh, as the Government Engineering College (GEC), Bhopal.
                  </p>
                  <div className="flex items-center flex-wrap ">
                    <p className="text-indigo-300 inline-flex items-center md:mb-2 lg:mb-0">
                      Click Here For More Information
                      <svg
                        className="w-4 h-4 ml-2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M5 12h14"></path>
                        <path d="M12 5l7 7-7 7"></path>
                      </svg>
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>

          {/* Twelveth Card  */}
          <div className="p-4 sm:w-1/2 lg:w-1/3">
            <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden hover:bg-gray-100 hover:text-black">
              <img
                className="lg:h-72 md:h-48 w-full object-cover object-center"
                src={uniImg12}
                alt="Img"
              />
              <div className="p-6 hover:bg-gray-100 hover:text-black transition duration-300 ease-in">
                <a href="https://www.jntua.ac.in/" className="cursor-pointer">
                  <h1 className=" cursor-pointer text-2xl font-semibold mb-3">
                  Jawaharlal Nehru Technological University
                  </h1>
                  <p className="leading-relaxed mb-3 cursor-pointer">
                  Jawaharlal Nehru Technological University Anantapur, Ananthapuramu is one of the top public universities in Anantapur, India. Founded in 1946, it has since 1973 been a constituent college of JNTU, as set by The Jawaharlal Nehru Technological University Act, 1972.It is ranked #601-650 in Asian University Rankings 2023.
                  </p>
                  <div className="flex items-center flex-wrap ">
                    <p className="text-indigo-300 inline-flex items-center md:mb-2 lg:mb-0">
                      Click Here For More Information
                      <svg
                        className="w-4 h-4 ml-2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M5 12h14"></path>
                        <path d="M12 5l7 7-7 7"></path>
                      </svg>
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>

 {/* CARD ENDS HERE */}

        </div>
      </div>
    </section>
    </div>
  );
}

export default UniversityCards;
