

export const footerProductLinks = [
    {
      name: "Home",
      link: "/"
    },
    {
      name: "About Us",
      link: "/about"
    },
    {
      name: "Our Story",
      link: "/about"
    },
    {
      name: "Achivements",
      link: "/"
    },
  ];
  
  export const footercompanyLinks = [
    {
      name: "Registration",
      link:"/registration"
    },
    {
      name: "Who are We?",
      link:"/chairman"
    },
    {
      name: "University",
      link:"/university"
    },
    {
      name: "Courses",
      link:"/courses"
    },
    {
      name: "Login",
      link:"/login"
    },
  ];
  
  export const footerSupportLinks = [
    {
      name: "Infront Of Vinay Singh Mukhiya",
    },
    {
      name: "Near RBL Bank Raghunathpur, Motihari",
    },
    {
      name: "Bihar - 845401",
    },
    {
      name: "satyagraheducationalgroup@gmail.com"
    },
    {
      name: "+91 8877456111",
    },
  ];
  
